
.step-sidebar-wrapper{
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  z-index: 4;
  position: relative;
  overflow: auto;
  // display: flex;
  // flex-flow: column;
  .logo{
    padding-left: 9px;
  }
  .nav{
    
    &:first-child{
      flex: 1;
    }
    & > li > a{
      color: #707071;
      display: block;
      position: relative;
      text-transform: uppercase;
      cursor: pointer;
      font-size: 0.85475rem;
      font-weight: 400;
      line-height: 1.625rem;
      margin: 10px 15px 0;
      border-radius: 30px;
      text-decoration: none;
      padding: 10px 8px;
      i, svg{
        font-size: 20px;
        //float: left;
        margin-right: 12px;
        line-height: 30px;
        width: 34px;
        text-align: center;
        // color: #707071;
        color: white;
        position: relative;
      }
      svg{
        font-size: 30px;
      }
      p{
        line-height: 30px;
        position: relative;
        display: block;
        height: auto;
        white-space: nowrap;
        margin: 0;
        color: white;
      }
    }
    li{
      &.divider{
        width: 27%;
        border-top: solid 1px #d7ecd7b3;
        margin: 0 auto;
        margin-top: 10px;
      }
    }
  }
}
.step2SidebarHeader{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  padding: 10px 25px;
  .feedSourceLabel{
    flex: 1 1;
    font-size: 18px;
  }
  
  .step2BackIcon{
    color: black;
    display: block;
    text-align: right;
    margin-right: 12px;
    svg{
      font-size: 20px;
    }
  }
}
.nav{
  margin-top: 0px;
  li > a {
    margin-top: 0;
    margin-bottom: 0;
    color: black;
    padding: 10px 0;
  }
  li{
    transition: all ease-in .1s;
    font-family: monospace;
    &.active{
      background: #f1f3fb;
      box-shadow: 0 0 0px 2px #84a0e6 inset;
    }
  }
}
