body{
    // background: linear-gradient(45deg, #6917bf, #6917bf) !important;
}
.auth-page{
    background-color: white;
    .logo_video{
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .login-page{
        background: #073088 !important;
        display: flex;
        flex-flow: column;
        justify-content: center;
        &.login-fade-page{
            transition: opacity 0.5s ease-in;
            &.show{
                opacity: 1;
            }
            &.hidden{
                opacity: 0;
            }
        }
        .login-page-inner{
            padding: 100px;
            //max-width: 790px;
            //background: linear-gradient(white 65%, #e4e4f5db);
            background: white;
            flex: 1 1;
            margin: 50px;
            border-radius: 30px;
            display: flex;
            flex-flow: row-reverse;
            position: relative;
            .login-demo-button{
                position: absolute;
                right: 31px;
                top: 20px;
                color: white;
                padding: 19px 32px;
                text-transform: uppercase;
                z-index: 1000;
            }
            .login-page-logo{
                flex: 1 1;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                overflow: hidden;
                top: -31px;
                img{
                    //width: 500px;
                }
                &::after{
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 10px;
                    height: 100%;
                    background-color: white;
                }
                video{
                    width: 100%;
                    /* max-width: 852px; */
                    position: absolute;
                    //top: -47%;
                    min-width: 900px;
                }
            }
            .login-page-content{
                display: flex;
                align-items: center;
                .login-header{
                    h1{
                        color: #171953;
                        font-size: 3.7rem;
                        font-weight: 600;
                        line-height: 1.4;
                        font-family: sans-serif;
                    }
                }
                .login-body{
                    .form-group{
                        .MuiFormControl-root{
                            &.MuiTextField-root{
                                width: 100%;
                            }
                            label{
                                color: #d3d5ff;
                                transform: translate(0, 12px);
                                font-size: 18px;
                                letter-spacing: 1px;
                                font-weight: bold;
                                font-family: monospace;
                                &.MuiInputLabel-shrink{
                                    transform: translate(0, 0px) scale(0.75);
                                }
                            }
                            .MuiInputBase-input{
                                min-width: 250px;
                            }
                            .MuiInputBase-root.MuiInput-underline{
                                &::before{
                                    border-bottom-color: #d3d5ff;
                                }
                                &::after{
                                    border-bottom-color: #6917bf;
                                }
                                &.Mui-error{
                                    &::after {
                                        transform: scaleX(1);
                                        border-bottom-color: #f44336;
                                    }
                                }
                            }
                            .Mui-focused{
                                &::after{
                                    border-bottom-color: #6917bf;
                                }
                            }
                        }
                        input{
                            &::placeholder{
                                color: #d4d6ff;
                            }
                        }
                        .MuiCheckbox-root{
                            .MuiSvgIcon-root{
                                font-size: 1rem;
                            }
                        }
                        .MuiFormControlLabel-label{
                            font-size: 15px;
                            color: #6917bf;
                            //font-weight: 600;
                        }
                    }
                    .rememberMeForm{
                        margin-top: 30px;
                        display: flex;
                        align-items: center;
                        .MuiFormControlLabel-root{
                            flex: 1 1;
                            margin: 0;
                            position: relative;
                            left: -10px;
                            span{
                                color: #5d62ef;
                            }
                        }

                        .forgotPasswordLink{
                            position: relative;
                            bottom: 3px;
                            font-size: 15px;
                            color: #5d7af3;
                            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                            font-weight: 400;
                            line-height: 1.5;
                            letter-spacing: 0.00938em;
                        }
                    }
                    .login-btns{
                        margin-top: 18px;
                        .loginBtn{
                            background: #5c61ef;
                            border-radius: 3px;
                            font-size: 17px;
                            letter-spacing: 2px;
                            font-weight: 400;
                            &:hover, &:active, &:focus{
                                background: #5a5ad8 !important;
                            }
                        }
                        .googleLoginBtn{
                            //color: black;
                            //background-color: #f4f4f4;
                            img{
                                width: 20px;
                                margin-right: 10px;
                            }
                        }
                    }
                    .getAccount{
                        color: #6065f0;
                        font-size: 15px;
                        margin-top: 36px;
                        a{
                            color: #6065f0;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 1128px) {
            display: block;
            .login-page-inner{
                display: block;
                .login-page-logo{
                    max-height: 280px;
                    video{
                        position: initial;
                    }
                }
            }
        }
        @media screen and (max-width: 600px){
            .login-page-inner{
                display: block;
                padding: 100px 55px;
                .login-page-content{
                    h1{
                        font-size: 2.6rem;
                    }
                }
                .login-page-logo{
                    video{
                        min-width: 610px;
                    }
                }
            }
        }
    }
    .demo-page{
        min-height: 100vh;
        background: #6917bf !important;
        display: flex;
        flex-flow: column;
        justify-content: center;
        .demo-page-inner{
            padding: 37px 100px 100px;
            background: white;
            flex: 1 1;
            margin: 50px;
            border-radius: 30px;
            position: relative;
            .demo-header{
                h1{
                    color: #171953;
                    font-size: 3.7rem;
                    font-weight: 600;
                    line-height: 1.4;
                    font-family: sans-serif;
                }
            }
            .demo-back-link{
                position: absolute;
                right: 31px;
                top: 20px;
                color: white;
                padding: 19px 32px;
                text-transform: uppercase;
                z-index: 1000;
            }
        }

        @media screen and (max-width: 700px){
            .demo-page-inner{
                padding: 100px 55px;
            }
        }
    }
}