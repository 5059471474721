body{
  background-color: #073088 !important;
}
.white-content{
  .navbar {
    > .container-fluid{
      > .navbar-collapse{
        /*flex: initial;
        padding-top: 17px;
        padding-left: 19px;*/
        padding-right: 30px;
      }
    }

    .searchNavWrap{
      margin-left: auto;
      margin-right: 13px;
      button{
        box-shadow: rgba(0, 0, 0, 0.1) 0 1px 8px 0, rgba(0, 0, 0, 0.15) 0 4px 6px 0;
        margin-left: 0;
        border-radius: 50%;
        padding: 10px;
        transition: all ease .3s;
        background: #203263;
        i{
          font-size: 20px;
          color: white;
        }
        &:hover, &:focus{
          box-shadow: rgba(0, 0, 0, 0.1) 0 1px 8px 0, rgba(0, 0, 0, 0.15) 0 4px 6px 0 !important;
          transform: scale(1.1) !important;
          background: #203263 !important;
        }
      }
      input{
        color: black;
        flex: 1 1;
        padding-bottom: 7px;
        padding-left: 20px;
        border: none;
        opacity: 1;
        background: transparent;
        border-radius: 0;
        font-size: 12px;
        width: 300px;
        height: 43px;
      }
    }
    ul.navbar-nav{
      margin-left: 0 !important;
      .nav-link{
        i, .caret{
          color: white !important;
        }
      }
      .nav-notification-link{
        display: flex;
        align-items: center;
        .dropdown-toggle.nav-link{
          border-radius: 50%;
          background: #203263;
          height: 40px;
          width: 40px;
          position: relative;
          i{
            position: relative;
            left: 2px;
          }
          .notification{
            right: 2px;
            top: 1px;
          }

          &:hover, &:focus{
            box-shadow: rgba(0, 0, 0, 0.1) 0 1px 8px 0, rgba(0, 0, 0, 0.15) 0 4px 6px 0 !important;
            transform: scale(1.1) !important;
            background: #203263 !important;
          }
        }
      }

      .photo{
        display: inline-block;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        vertical-align: middle;
        overflow: hidden;
        img{
          max-width: initial;
          width: 60px;
          position: relative;
          right: 10px;
          bottom: 4px;
        }
      }
    }
  }
  .main-panel{
    height: 100vh;
    border-top: none;
    overflow: auto;
    .navbar{
      width: 100vw;
      right: 0;
      padding-right: 0;
      flex-flow: column;
      @media (max-width: 1199.98px) and (min-width: 768px){
        width: 100%;
      }
      /*@media (max-width: 991.98px){
        top: 0;
      }*/
    }
    .content{
      //padding: 82px 30px 30px 20px;
      //min-height: 100vh;
      position: relative;
      // padding: 23px 20px;
      padding: 0;
      margin: 82px 30px 0 20px;
      min-height: calc(100vh - 100px);
      height: calc(100vh - 100px);
      background: #F5F8FF;
      border-radius: 5px;
      overflow: auto;
      //top: 80px;
      //left: 280px;
      //width: calc(100vw - 312px);
      //padding: 0;
    }
  }

  .sidebar{
    height: calc(100vh - 48px);
    margin-top: 20px;
    .sidebar-wrapper{
      .logo{
        .input-group{
          input{
            &::placeholder{
              color: white;
            }
          }
        }
      }
    }
  }
  .sidebar:hover + .main-panel{
    .navbar{
      width: calc(100vw - 230px);
    }
  }

  //mini sidebar
  &.sidebar-mini{
    .main-panel{
      .navbar{
        width: calc(100vw - 80px);
      }
    }
    @media (min-width: 992px){
      .sidebar:hover {
        width: 230px;
      }
    }
  }
}
.modal-search .modal-dialog{
  margin: 0;
  margin-left: 120px;
  max-width: 301px;
  .modal-header{
    .close{
      padding: 9px;
      top: 24px !important;
    }
  }
}
.table{
  thead{
    tr{
      th{
        color: #524d4d;
        font-weight: 600;
        border-color: #f4f4f4;
      }
    }
  }
  tbody{
    tr{
      td{
        color: #212121 !important;
        border-color: #f4f4f4;
        a{
          color: #212121;
        }
      }
    }
  }
}
//.table > thead > tr > th{
//  color: #524d4d;
//  font-weight: 600;
//}

.form-control, .form-control:focus{
  color:black;
}
textarea{
  border-radius: 6px !important;
}
.testBox{
  position: relative;
  &::before{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    /* border: solid 7px transparent; */
    /* border-top-color: white; */
    border: solid 1px red;
    transform: rotate(45deg);
    bottom: -6px;
    left: 50%;
    z-index: 0
  }
}
a:hover, a:focus{
  color: #383333;
}
h2 {
  color: #616161
}